.login-page {
  text-align: center;
  height: 100vh;
  background-color: #c8eafa;
  overflow: hidden;
}
  
.li-logo-container {
  text-align: center;
  margin-top: 150px;
  margin-bottom: 50px;
}
 
.li-logo-container img {
  max-width: 90px;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
}
  
.login-form {
  max-width: 300px;
  margin: 0 auto;
}
  
.li-form-group {
  position: relative;
  margin-bottom: 15px;
  text-align: left;
}

.li-form-group img {
  position: absolute;
  width: 25px;
  height: 25px;
  margin-top: 20px;
  margin-left: -33px;
  transform: translateY(-50%);
  cursor: pointer;
}
  
label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
}
  
input {
  width: 92%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}
  
.li-forgot-password {
  margin-bottom: 15px;
  display: flex;
  align-items: left;
}
  
.li-forgot-password-button {
  background-color: transparent;
  border: none;
  font-size: 14px;
  color: #F05514;
  cursor: pointer;
}

.li-forgot-password-button:hover {
  text-decoration: underline;
}
  
.li-continue-button {
  display: block;
  width: 100%;
  padding: 15px;
  background-color: #F05514;
  color: #ffffff;
  border: 2px solid black;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box;
}
  
.li-continue-button:hover {
  background-color: #D94500;
}
  
.login-page p {
  font-size: 14px;
  margin-top: 70px;
}

a {
  color: #F05514;
  text-decoration: none;
}
  
a:hover {
  text-decoration: underline;
}