html {
  background-color: #c8eafa;
}

.dashboard {
  text-align: center;
  padding: 20px;
}

.dash-logo-container {
  text-align: center;
  margin-top: 8px;
  margin-bottom: 22px;
}
   
.dash-logo-container img {
  max-width: 90px;
}

.settings-container {
  position: absolute;
  top: 10px;
  right: 10px;
}

.settings-button {
  max-width: 30px;
  max-height: 30px;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 999;
}

.dropdown-menu button {
  width: 115px;
  background-color: #a8b6bf;
  color: black;
  border: 2px solid black;
  cursor: pointer;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-right: -45px;
  transform: translate(-50%, -50%);
  background-color: #F05514;
  padding: 20px;
  padding-right: 15px;
  padding-left: 10px;
  border: 3px solid black;
  border-radius: 5px;
  z-index: 9999;
}

.reset-popup-content h2 {
  margin-top: -10px;
  margin-bottom: 10px;
}

.delete-popup-content h2 {
  margin-top: -10px;
  margin-bottom: 10px;
}

.delete-popup-content h4 {
  margin-top: 0px;
  margin-bottom: 15px;
}

.reset-password-button {
  display: block;
  width: 50%;
  padding: 15px;
  background-color: #c8eafa;
  color: red;
  font-weight: bold;
  border: 2px solid black;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box;
  float: right;
}

.reset-password-button:hover {
  background-color: #a5d4ed;
}

.delete-password-button {
  display: block;
  width: 50%;
  padding: 15px;
  background-color: #c8eafa;
  color: red;
  font-weight: bold;
  border: 2px solid black;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box;
  float: right;
}

.delete-password-button:hover {
  background-color: #a5d4ed;
}

.current-password-label {
  text-align: left;
}

.current-password-input {
  margin-bottom: 15px;
}

.cancel-button {
  display: block;
  width: 50%;
  padding: 15px;
  background-color: #c8eafa;
  color: black;
  border: 2px solid black;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box;
  float: left;
}

.cancel-button:hover {
  background-color: #a5d4ed;
}

.AccuWeather-Attribution {
  display: flex;
  justify-content: center;
  margin-top: -35px;
  margin-bottom: -25px;
}

.AccuWeather-Attribution img {
  position: relative;
  max-width: 120px;
  margin-bottom: -23px;
  margin-left: 9px;
}

.weather-section {
  width: auto;
  margin-top: 20px;
  margin-left: -10px;
  margin-right: -10px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
  
.preview-indicator {
  background-color: #333333; 
  color: #ffffff;
  padding: 5px 10px; 
  font-size: 11px;
  text-align: center;
  border-radius: 20px;
  display: inline-block;
  margin-bottom: 5px;
  cursor: default;
}
  
.weather-messages {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
  
.weather-messages div {
  max-width: 170px;
  position: relative;
}

.weather-message {
  background-color: #333333;
  color: #ffffff;
  border-radius: 20px;
  padding: 8px;
  margin: 10px auto;
  margin-right: 5px;
  margin-left: 5px;
}

.weather-message hr {
  border: none;
  height: 1px;
  margin: 5px 0;
}

.weather-message p {
  font: 13px "San Francisco", sans-serif;
  margin: 1px 0 0;
  position: initial;
  padding: 0px;
  border-radius: 10px;
  max-width: 100%;
  text-align: left; 
}

.weather-message::after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 20px 10px 0;
  border-color: #333333 transparent transparent transparent;
  bottom: -12px;
  left: -4.3%;
  transform: rotate(35deg);
  border-right-width: 12px;
  border-left-width: 5px;
  border-top-width: 21px;
  border-bottom-width: 5px;
}

.zip-code-section {
  margin-top: 20px;
  margin-left: -10px;
  margin-right: -10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
 
.zip-code-form {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
  
.zip-code-form input {
  width: auto;
  margin-right: 20px;
  padding: 10px;
  border: 1px solid #000;
  border-radius: 5px;
  box-sizing: border-box;
}

.zip-code-form input#zipcode {
  width: 120px;
}

@media (max-width: 320px) {
  .zip-code-form input#zipcode, input#deliverytime {
    margin: auto;
  }
}

.zip-code-form input#deliverytime {
  height: 39px;
  cursor: pointer;
}

.zip-code-form button {
  height: 39px;
  width: auto;
  padding: 12px 20px;
  background-color: #F05514;
  color: #ffffff;
  border: 2px solid black;
  border-radius: 5px;
  cursor: pointer;
}

.zip-code-form button:hover {
  background-color: #D94500;
}

.password-error-message {
  color: white;
  font-size: 12px;
  margin-top: 4px;
  text-align: left;
}
 
.delete-password-error-message {
  color: white;
  font-size: 12px;
  margin-top: -5px;
  margin-bottom: 10px;
  text-align: left;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 4px;
  text-align: left;
}

.zip-codes-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
  margin-left: -10px;
  margin-right: -10px;
  padding: 20px;
}

.zip-codes-list table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.zip-codes-list th, .zip-codes-list td {
  border: 1px solid #333333;
  padding: 8px;
  text-align: left;
}

.zip-codes-list td {
  font: 15px "JetBrains Mono", monospace;
}

.zip-codes-list td input {
  width: 83%;
  cursor: pointer;
}

.zip-codes-list th {
  background-color: #ffffff;
  -webkit-text-fill-color: #333333;
}

.delete-text, .edit-text {
  position: relative;
  top: -15px;
  margin-bottom: -8px;
  font: 15px "JetBrains Mono", monospace;
  font-weight: bold;
  text-align: center;
}

.delete-text {
  color: red;
}

.cancel-confirm-container {
  display: flex;
  justify-content: space-around;
}

.cancel-icon {
  max-width: 30px;
  max-height: 30px;
  cursor: pointer;
}

.confirm-icon {
  max-width: 30px;
  max-height: 30px;
  cursor: pointer;
}

.edit-delete-container {
  display: flex;
  flex-direction: row;
  justify-content:space-around;
  align-items: center;
}

.edit-icon {
  max-width: 27px;
  max-height: 27px;
  cursor: pointer;
}

.delete-icon {
  max-width: 27px;
  max-height: 27px;
  cursor: pointer;
}

.deleting-row {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.logout-button button {
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 10px 20px;
  background-color: #F05514;
  color: #ffffff;
  border: 2px solid black;
  border-radius: 5px;
  cursor: pointer;
}

.logout-button button:hover {
  background-color: #D94500;
}